@import "bootstrap/dist/css/bootstrap.min.css";

body {
    overflow-x: hidden;
}

.logo-header {
    height: 70px;
    display: block;
}

#personal-info {
	width: 100%;
}

.form-control {
    padding: 1.6rem 0.75rem;
}

.carousel-item { 
    visibility: hidden;
    display: flex!important;
    flex-direction: column;
    align-self: center;  
    transition: none;
}

.carousel-inner {
    display: flex;
}

.slide-inner img {
    height: 300px;
    margin-bottom: 20px;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    visibility: visible;
}

.homeStart {
    position: relative;
}

.homeStart .left-col .slide-inner a {
    display: none;
}

#our-advantages, .bg-light {
    background: #f9f9f9;
}

.how-it-works-number {
    background: #ea6b48;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    line-height: 1;
}

.btn-light {
    background: #ea6b48;
    color: #fff;
}

.btn-light:hover {
    background: #be5235;
    color: #fff;
}

#our-services ul li:before {
    content: '';
    background: url(images/check.svg) no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;    
    top: 5px;
}

#our-services ul li {
    list-style: none;
    margin-bottom: 10px;
    position: relative;
}

#our-advantages h5 {
    color: #ea6b48;
}

.our-advantages svg,
.our-strategy svg,
#our-advantages svg {
    width: 64px;
    height: 64px;
}

#return-funds .row.return-funds-inner {
    border-top: 2px solid #007bff;
    padding-top: 15px;
    margin-top: 35px;
}

#return-funds .row.return-funds-inner > div:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    left: calc(50% - 10px);
    top: -26px;
    border-radius: 50%;
}

    #return-funds .row.return-funds-inner {
        border-top: none;
        border-left: 1px solid #fff;
        padding-left: 15px;
        margin-left: 15px;
    }

    #return-funds .row.return-funds-inner > div:before {
        top: calc(50% - 10px);
        left: -21px;
    }
    
#details {
    background: #f7f7f7;
    border-radius: 10px;
}

.slider {
    position: relative;
}

.slider .bg-banner-image {
    position: absolute;
    z-index: -2;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.slider h2 {
    color: #000;
    text-transform: uppercase;
    font-weight: 500;
}

.homeStart .right form {
    z-index: 50;
}

.homeStart .right .btn-primary {
     width:100%;
}

.homeStart .right input {
    border-radius: 5px;
}

.PhoneInput {
    display: flex!important;
}

.about-img {
    width: 250px;
    position: absolute;
    top: -85px;
    right: 0;
    z-index: -1; 
}

.PhoneInputInput {
    outline: none;
    border: none;
}

.form-group {
    position: relative;
}

.imgCheckPhone {
    width: 20px;
    position: absolute;
    bottom: 15px;
    right: 10px;
}

h3 {
    font-size: 2rem;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}

h4 {
    font-size: 19px;
    color: #353B8B;
    font-weight: 500;
    text-transform: uppercase;
}

h5 {
    text-transform: uppercase;
}

.btn-primary, .btn-light {
    border-radius: 15px;
    padding: 20px 35px;
    font-size: 24px;
}

.homeStart .left-col p {
    font-size: 18px;
    color: #969696;
}

#map-section iframe {
    width: 100%;
}

.slider {

}

.testimonial {
    background: #f9f9f9;
    margin: 5px;
    padding: 20px;
}

.testimonial .author img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 5px;
}

.testimonial .author {
        display: flex;
        align-items: center;
}

.carousel-control-prev {
    left: -90px;
}

.carousel-control-next {
    right: -60px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    background-color: #000;
    padding: 15px;
    display: block;
    border-radius: 50%;
    background-size: 15px;
    background-position-x: 7.5px;
}

#testimonials .carousel.slide {
    width: 100%; 
    padding: 35px;
}

#testimonials .carousel.slide p {
    font-style: italic;
}

#testimonials .user-avatar img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
}

footer .navbar {
    justify-content: space-between;
}

footer .copyright {
    color: #999;
    padding: 10px;
    width: 100%;
}

footer {
    display: flex;
    padding-top: 45px; 
    padding-bottom: 45px; 
    flex-wrap: wrap;
}

footer .social a {
    color: #000;
    text-decoration: none;
    margin-right: 10px;
}

footer .privacy {
    width: 100%;
}

footer .social {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}

footer .social svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.bg-dark {
    background: #170a20!important;
}

section.bg-dark {
    color: #fff;
}

section.bg-dark h3 {
    color: #b5c2f2;
}

@media screen and (max-width: 1200px) {
    h5 {
        font-size: 18px;
    }
}

@media screen and (min-width: 768px) {
    .homeStart:after {
        content: "";
        width: 300px;
        height: 4px;
        background: #ea6b48;
        position: absolute;
        bottom: 0;
        left: calc(50% - 150px);
    }    
}

@media screen and (max-width: 768px) {
    .homeStart .left-col .slide-inner a {
        display: block;
    }
    
    .carousel-control-prev {
        left: -60px;
    }

    .carousel-control-next {
        right: -60px;
    }
    
    h3 {
        font-size: 18px;
        font-weight: 500;
    }
    
    .homeStart .left-col p {
        font-size: 16px;
    }
    
    .our-strategy .col-md-4 {
        order: -1;
    }
    
    .our-strategy .col-md-4 img,
    #our-advantages img {
        max-height: 250px;
        margin-bottom: 35px;
    }
    
    #testimonials .carousel-control-prev {
        left: -10px;
    }
    
    #testimonials .carousel-control-next  {
        right: -10px;
    }
    
    #testimonials .user-avatar img {
        width: 64px;
    }
    
    footer .navbar {
        padding: 20px 10px;
    }
    
    footer .navbar-nav {
        flex-wrap: wrap;
        padding-left: 35px;
    }
    
    footer .navbar-nav > a {
        width: 50%;
    }
    
    .testimonials-list {
        flex-direction: column;
    }
}

@media screen and (max-width: 500px) {
    .slider h2 {
        font-size: 21px; 
    }
    
    .slide-inner img {
        height: 150px;
    }
    
    .btn-primary, .btn-light {
        font-size: 18px;
        padding: 10px 10px;
    }
    
    #our-advantages {
        padding: 15px;
    }

    #our-services, #how-it-works {
        padding: 30px;
    }
    
    #our-services .col-md-6:not(.our-advantages) {
        order: 1;
    }
}

@media screen and (max-width: 450px) {
    .carousel-control-prev {
        left: -40px;
    }

    .carousel-control-next {
        right: -40px;
    }
    
    #testimonials h5 {
        font-size: 11px;
    }
    
    footer .navbar-nav > a {
        width: 100%;
    }    
}